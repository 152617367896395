:root {
  --body-bg: #ffffff;
  --achivement-title-bg: #ffffff;
  --achivement-title-darkest: #ffffff;
  --modal-header-bg: #b5e8ff;
}

@font-face {
  font-family: 'golden-plain';
  src: url('/fonts/golden-plain.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/Helvetica Neue Bold.woff2') format('woff2'),
    url('/fonts/Helvetica Neue Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('/fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Semibold';
  src: url('/fonts/ProximaNova-Semibold.woff2') format('woff2'),
    url('/fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Bold';
  src: url('/fonts/ProximaNova-Bold.woff2') format('woff2'),
    url('/fonts/ProximaNova-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arya';
  src: url('/fonts/Arya-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aktiv Grotesk Bold';
  src: url('/fonts/AktivGrotesk-Bold.woff2') format('woff2'),
    url('/fonts/AktivGrotesk-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 300;
  color: #172643;
}

body.noscroll {
  overflow: hidden;
}

* {
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  outline: 0px;
  scroll-behavior: smooth;
}

.pointer {
  cursor: pointer;
}

.multi-check-wrap {
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-gap: 16px;
  align-items: center;
  justify-content: flex-start;
}

.multi-check {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  border: 1px solid #b5e8ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

label {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

input[type='checkbox'] + label img {
  opacity: 0;
  transition: all 100ms;
}

input[type='checkbox']:checked + label img {
  opacity: 1;
}

input[type='radio'] + label img {
  opacity: 0;
  transition: all 100ms;
}

input[type='radio']:checked + label img {
  opacity: 1;
}

.time-filter-wrap input {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.time-filter-wrap label {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  text-align: center;
  height: 85px;
  border: 1px solid #b5e8ff;
  cursor: pointer;
  grid-gap: 6px;
}
.time-filter-wrap label span {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
}
.time-filter-wrap label span:nth-child(2) {
  font-weight: 300;
}
.time-filter-wrap input:checked + label {
  border-color: transparent;
  background-color: #ffc9c9;
  background: -webkit-linear-gradient(
    136.67deg,
    #ffc9c9 0%,
    #f4c9ff 52.41%,
    #b5e8ff 100%
  );
  background: linear-gradient(
    136.67deg,
    #ffc9c9 0%,
    #f4c9ff 52.41%,
    #b5e8ff 100%
  );
}

.time-filter-wrap input:checked + label span:first-child {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
}

/* Slick Arrow Custom */

.slick-arrow::before,
.slick-arrow::after {
  display: none;
}

.slick-arrow {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.franchise-slider > div > .slick-next {
  background: url(/images/right-arrow.png) center center no-repeat !important;
  right: 0;
  top: 50%;
}

.franchise-slider > div > .slick-prev {
  background: url(/images/left-arrow-franchise.png) center center no-repeat !important;
  z-index: 1;
  top: 50%;
  left: 0;
}
.franchise-slider .slick-dots {
  display: none !important;
}
@media (max-width: 599px) {
  .franchise-slider > div > .slick-next {
    right: -20px;
  }
  .franchise-slider > div > .slick-prev {
    left: -20px;
  }
}

.slick-list {
  margin: 0 30px;
}
.slick-slide img {
  display: block;
  width: 100%;
}

.franchise-slider {
  padding-bottom: 50px;
}

.gradient-link {
  margin-bottom: 30px !important;
  font-weight: normal !important;
}

.gradient-link.user {
  font-size: 29.49px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  max-width: fit-content;
  position: relative;
}

.gradient-link:hover::before {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 3px;
  width: 100%;
  border: 0px;
  border-radius: 3px;
  background-color: #ffc9c9;
  background: -webkit-linear-gradient(
    136.67deg,
    #b5e8ff 0%,
    #f4c9ff 75%,
    #ffc9c9 100%
  );
}

.gradient-link.user::before {
  bottom: -4px;
  background: -webkit-linear-gradient(136.67deg, #f4c9ff 0%, #ffc9c9 100%);
}

/* Password Eye */

.strike-eye::before {
  content: '';
  width: 24px;
  height: 2px;
  border: 1px solid #fff;
  border-radius: 2px;
  position: absolute;
  top: 31%;
  left: 50%;
  background: -webkit-linear-gradient(
    136.67deg,
    #ffc9c9 0%,
    #f4c9ff 52.41%,
    #b5e8ff 100%
  );
  transform: translateX(-50%) rotate(135deg);
}

/* Search Input Styles */

.search-input-wrap {
  display: grid;
  grid-template-columns: 1fr 16px;
  grid-gap: 16px;
  align-items: center;
  border: 1px solid #cacaca;
  border-radius: 47px;
  padding: 5px 20px;
}

.search-input-wrap input {
  border: 0px;
  font-size: 16px;
  line-height: 26px;
  color: #737373;
  background: none;
}

/* Pricing Page */

.best-deal::before {
  content: '';
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 0px;
  border-left: 40px solid #b5e8ff;
  border-right: 40px solid #b5e8ff;
  border-bottom: 8px solid transparent;
  top: 100%;
  left: 0px;
  transition: border 100ms linear;
}

.best-deal.active::before {
  border-left-color: #ecf9ff;
  border-right-color: #ecf9ff;
}

@media (max-width: 599px) {
  .slick-list {
    margin: 0px;
  }
  .best-deal::before {
    border-left: 38px solid #b5e8ff;
    border-right: 38px solid #b5e8ff;
    border-bottom: 7px solid transparent;
  }
}
/* Footer */

.brandname {
  background: -webkit-linear-gradient(
    40.67deg,
    #ffc9c9 0%,
    #f4c9ff 34.41%,
    #b5e8ff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 175px;
}

.s-footer img {
  border-left: 25px solid #fff;
  border-right: 25px solid #fff;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.s-footer::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 3px;
  background: -webkit-linear-gradient(
    0deg,
    #ffc9c9 0%,
    #f4c9ff 52.41%,
    #b5e8ff 100%
  );
  z-index: 0;
}

/* Extras List */

.extra-items-list:last-child {
  /* border-bottom: 0px; */
}

/* Stripe Custom Fields */

.stripe-card-wrap .StripeElement {
  margin-bottom: -7px;
  padding-bottom: 7px;
  border-bottom: 1px solid #172643;
}

/* Special Extra Buttons */

.link-btn {
  background-color: transparent;
  border: 0px;
  padding: 0px;
  width: max-content;
  margin: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
}

/* Signature Box Styles */

.signature-box {
  width: 100%;
  height: 150px;
  margin-bottom: 30px;
  box-shadow: 0px 5px 23px 0px rgba(23, 38, 67, 0.1);
}

@media screen and (max-width: 599px) {
  .signature-box {
    height: 120px;
  }
}
/* Text Truncate Button */

.more-less-info {
  font-size: 12px;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
}

.more-less-info a {
  font-size: 16px;
  text-transform: uppercase;
  color: #172643;
}

/* Logo Styles */

.brand-logo {
  width: 200px;
  cursor: pointer;
}

.book-for-who {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  padding: 0px 40px 20px;
}

/* Modal */

.ReactModal__Overlay {
  z-index: 10;
}

/* Menu icons */

button.menu-action-icon {
  height: 24px;
  text-align: center;
  width: 33px;
  border-radius: 0px;
}

.menu-action-icon img {
  height: 23px;
}

div.ReactModal__Overlay {
  background-color: rgba(23, 39, 67, 80%) !important;
}

.f-modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: #fff;
  z-index: 20;
}

.f-modal-header {
  padding: 12.5px 22px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 15px;
  grid-gap: 10px;
  position: sticky;
  top: 0px;
  z-index: 1;
  align-items: center;
  background-color: #172743;
}

button.f-modal-close {
  width: auto;
  margin-right: 0px;
}

.achivement-title {
  padding: 10px;
  background: var(--achivement-title-bg, #ffffff);
}

body {
  background: #ffffff;
}

body.gradient-bg {
  background: var(--body-bg, #ffffff);
}

.hideIt {
  display: none;
  pointer-events: none;
}

/* Button Font */

.StyledBtnFonts {
  font-size: 15px !important;
}

/* Slick Slider Styles */

.slick-disabled {
  opacity: 0.4;
  pointer-events: none;
}

.slick-dots ul li {
  width: auto;
  height: auto;
}

.slick-dots ul li.slick-active div {
  border-bottom: 1px solid #f4c9ff;
}

.slick-dots ul li:nth-of-type(2).slick-active div {
  border-bottom: 1px solid #f4c9ff;
}

.slick-dots ul li:nth-of-type(3).slick-active div {
  border-bottom: 1px solid #ffc9c9;
}

.slick-dots ul li:nth-of-type(4).slick-active div {
  border-bottom: 1px solid #b5e8ff;
}

.slick-dots ul li div {
  padding-bottom: 5px;
  border-bottom: 0px solid #b5e8ff;
}

.homeSlides .slick-next {
  right: 35px;
  z-index: 7;
}
.homeSlides .slick-prev {
  left: 35px;
  z-index: 7;
}

.homeSlides.slick-slider {
  z-index: 6;
}

.homeSlides .slick-dots ul {
  margin-left: -10px;
}

.homeSlides .slick-dots ul li {
  margin: 0 10px;
}

.instructor-slider li.slick-active span {
  color: #172743;
}

.StyledBtnFonts {
  font-size: 15px !important;
}

.showIt-mob {
  display: none;
}

.out-bounds {
  margin-left: -20px;
  margin-right: -20px;
}
.gradient-stroke-header {
  color: #172643;
  font-family: Arya;
  font-size: 18px;
  letter-spacing: 2.25px;
  line-height: 19px;
  text-align: center;
}

.class-subheading {
  padding: 20px 0 25px !important;
  max-width: 100% !important;
}

@media (max-width: 700px) {
  .out-bounds {
    margin-left: auto;
    margin-right: auto;
  }
  .class-subheading {
    padding: 0px 0 20px !important;
  }
}

.availabilty-seelction {
  margin: auto;
  width: fit-content;
}
.underline {
  text-decoration: underline;
}
.text-touch {
  padding: 30px 30px;
  text-align: center;
  color: #737373;
  font-weight: 300;
}
/* Mobile Styles */
.location-select {
  margin: auto;
  width: fit-content;
}
.btn-members {
  margin: 37px 0 0px;
}
.btn-members span {
  font-size: 22.5px;
  letter-spacing: 1.5px;
  color: #152542;
  font-weight: 400;
  padding: 8px 10px 3px;
  background-color: rgba(255, 255, 255, 0.7);
}

.join-gradient {
  background: #172643 !important;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffc9c9;
}
.join-gradient span {
  width: 100%;
  margin-right: 0;
}
.join-gradient:disabled {
  padding: 12px 16px;
  box-shadow: none;
  background-clip: none;
  background: #f1f2f7 !important;
  color: #b6b8ba !important;
  -webkit-text-fill-color: #b6b8ba !important;
  line-height: 10px;
}

.charge-text {
  display: block;
}
@media (max-width: 599px) {
  .btn-members span {
    margin: 30px 0 0px 0;
    font-size: 16px;
    letter-spacing: 1px;
  }

  .location-link {
    display: flex;
    width: 80%;
    margin: 0 auto;
  }
  .location-select {
    /* margin: auto; */
    width: 100%;
  }
  .waitlist-btns {
    width: 100%;
  }
  .text-center {
    text-align: center;
    width: 100%;
  }
  .text-touch {
    padding: 25px 30px;
    text-align: center;
    width: 42%;
    margin: 0 auto;
  }
  .plr-10 {
    padding: 0 10px;
  }
  .availabilty-seelction {
    width: 100%;
  }

  .out-bounds {
    margin-left: auto;
    margin-right: auto;
    max-width: 330px;
  }

  .hideIt-mob {
    display: none;
    pointer-events: none;
  }

  .showIt-mob {
    display: block;
  }

  .menu-action-icon img {
    height: 19px;
  }

  .brandname {
    max-width: 120px;
  }

  .brand-logo {
    width: 135px;
  }

  .gradient-link.user {
    font-size: 15px;
    margin-bottom: 1.5rem;
  }

  .gradient-link.user img {
    width: 15px;
  }

  .slick-arrow {
    /* display: none !important; */
  }
  .slick-prev {
    left: -20px;
  }
  .slick-next {
    right: -20px;
  }
  .book-for-who {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding: 0px 0px 20px;
  }

  /* .model-header, */
  /* .modal-body {
    padding: 20px !important;
  } */

  .instructor-class-details-class {
    padding: 0 20px 20px !important;
  }

  div.ReactModal__Content {
    max-width: 100% !important;
    width: 100% !important;
    margin: 0px !important;
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    top: unset !important;
    transform: translateX(0) !important;
    max-height: 90vh;
  }

  #milestone-id {
    width: 100%;
    height: 100%;
  }

  button.f-modal-close img {
    height: 14px;
  }

  .slick-slide img {
    margin: auto;
  }

  .slick-dots ul {
    justify-content: center;
    bottom: 6vh;
  }

  .sliderIcons img {
    height: 20px;
  }
}

input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0px;
}

/* .ElementsApp .InputElement {
  display: block;
} */

input[name='postalCode']::placeholder {
  font-weight: 500;
  /* color: #757575; */
  font-size: 16px;
}

#whyBrightSide-dropdown *::-webkit-scrollbar {
  width: 3px;
  border-radius: 4px;
}

#whyBrightSide-dropdown *::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.13);
}

#whyBrightSide-dropdown *::-webkit-scrollbar-thumb {
  background-color: #172643;
  outline: 1px solid #172643;
  border-radius: 4px;
}

/* phase 3 banner new changes */
.margin-top0 {
  margin-top: 0 !important;
}

.opening-section {
  display: none;
}

.opening-section-mob {
  /* padding-bottom: 50px; */
  position: relative;
  /* min-height: 680px; */
  padding: 60px 0;
  height: auto;
  background-image: url(/images/curve-banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom right;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 5;
  margin-top: -4vh;
  position: relative;
}

@media (max-width: 1024px) {
  .opening-section-mob {
    display: none;
  }
  .opening-section {
    display: block;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

@media (min-width: 2500px) {
  .opening-section-mob {
    padding: 60px 0;
  }
}
.mobile-show {
  display: none !important;
}
.mobile-hide {
  display: inline-block !important;
}

.desktop-show {
  display: block;
}
.desktop-hide {
  display: none;
}

@media (max-width: 1024px) {
  .btn-members {
    margin: 21px 0 10px;
  }
  .charge-text {
    display: inline;
  }
  .mobile-show {
    padding-top: 15px;
    display: block !important;
  }
  .mobile-hide {
    display: none !important;
  }
  .margin0 {
    margin: 0px !important;
    padding-top: 0;
  }
  .margin20 {
    margin-bottom: 20px;
  }

  .desktop-show {
    display: none;
  }
  .desktop-hide {
    display: block;
  }
}

.align-start {
  align-items: flex-start !important;
}
.margin-top9 {
  margin-top: -9vh;
}
/* @media (min-width: 1280px) and (max-width: 2100px) {
  .pre3-screenss {
    background-position: bottom !important;
  }
} */

.hide-overflow {
  overflow: hidden;
}

.yoga-woman {
  /* height: clamp(100vw, 70%, 100vw); */
  /* width: clamp(300px, 70%, 760px); */
  width: clamp(400px, 60%, 1000px);
  position: absolute;
  left: 55%;
  object-fit: contain;
  bottom: 0;
}
.phase3-icon {
  position: relative;
}
.phase3-icon::after {
  content: '';
  position: absolute;
  top: -16%;
  right: -110px;
  width: 164px;
  height: 150px;
  background-image: url(/images/svg/opening-july-2023.svg);
  background-repeat: no-repeat;
}
.joindate {
  display: none;
  margin-top: 45px;
  background: linear-gradient(#f4fafc, #f1f8fa);
  padding: 14px;
  margin-bottom: 0;
  text-align: center;
  font-weight: 700;
  font-size: 15px;
}

.curve-img {
  position: absolute;
  /* background: red; */
  height: 200px;
  bottom: 0;
  object-fit: contain;
  width: 100%;
  background-image: url(/images/white-curve.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  z-index: 2;
}
@media (min-width: 1430px) and (max-width: 1550px) {
  .yoga-woman {
    /* width: clamp(300px, 59%, 645px); */
    width: clamp(284px, 53%, 800px);
  }
  /* .phase3-icon::after {
    left: 90%;
  } */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .yoga-woman {
    display: none;
  }
  .curve-img {
    display: none;
  }
  .phase3-icon {
    position: initial;
  }
  .joindate {
    display: block;
  }
  .phase3firstsection {
    margin-top: 0px !important;
  }
  .phase3-icon::after {
    background-image: none;
  }
}

.font300 {
  font-weight: 300 !important;
}

@media (min-width: 600px) and (max-width: 1024px) {
  .joindate {
    margin-top: 72px;
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .font300 {
    letter-spacing: 1px !important;
    font-size: 20px !important;
  }
  .opening-section-mob {
    padding: 50px 0;
  }
}

/* @media (min-width: 1025px) and (max-width: 1539px) {
  .phase3-icon::after {
    left: 85%;
  }
} */
/* phase4 banner */

@media screen and (min-width: 1550px) {
  .phase4-banner:first-child {
    min-height: 1080px !important;
    height: 100vh !important;
  }
  .phase3firstsection {
    min-height: 1080px !important;
    height: 100vh !important;
    margin-top: 50px !important;
  }
}
@media (max-width: 1024px) {
  .phase4-banner:first-child {
    background: 100% !important;
    min-height: 600px !important;
    display: block !important;
    position: relative !important;
  }
}
@media (max-width: 599px) {
  .phase4-banner:first-child {
    margin-top: 45px !important;
  }
}

.details-section > div:first-child > div {
  grid-template-columns: 1fr;
}

@media (min-width: 1025px) and (max-width: 1500px) {
  .phase3-icon::after {
    right: -3px;
  }
}
@media (min-width: 2000px) {
  .phase3-icon::after {
    right: -200px;
  }
}
@media (min-width: 1025px) and (max-width: 1600px) {
  .phase3firstsection {
    min-height: 775px !important;
    margin-top: 50px !important;
  }
}

@media (min-width: 250px) and (max-width: 319px) {
  .joindate {
    display: block;
  }
  .phase3-icon {
    position: initial;
  }
}

.sticky-btn {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding: 15px 0 30px;
}

.hero-text-shrink {
  max-width: 100%;
}

@media (min-width: 1025px) and (max-width: 1170px) {
  .hero-text-shrink {
    max-width: 60%;
  }
}

.mob-home-img img,
.ipad-home-img img {
  height: 90vh;
  object-fit: cover;
  object-position: bottom;
}

@media (min-width: 600px) and (max-width: 1024px) {
  .tab-text > div {
    font-size: clamp(50px, 5vw, 70px);
    margin-top: 0;
  }
  .home-caption-wrap {
    padding: 0px !important;
    height: 0px;
  }

  .home-caption-wrap > div {
    margin-top: 30px;
  }
}

@media (min-width: 200px) and (max-width: 599px) {
  #infraredPage {
    margin-top: -5px;
  }
}
@media (min-width: 600px) and (max-width: 1025px) {
  #infraredPage {
    margin-top: -34px;
  }
}

.gift-card-booknw {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.floating-edit {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
  width: auto !important;
}
.extraadd-ons {
  grid-template-columns: auto !important;
  grid-gap: 10px;
}

.increment-decrement {
  display: grid;
  grid-template-columns: 1fr auto;
}

.listwrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 45px;
}
.marginbgtm {
  margin-bottom: 29px;
  color: #f4c9ff;
  background: linear-gradient(
    131.67deg,
    #ffc9c9 2%,
    #f4c9ff 40.41%,
    #b5e8ff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.book-btns {
  display: none;
  width: -webkit-fill-available;
  background: #fff;
  position: fixed;
  bottom: 0em;
  z-index: 7;
  margin: 0px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  padding: 30px 10px;
}
.book-btns button {
  font-size: 17px;
}

.transparent-background {
  background: transparent;
}

.qr-code-section {
  display: flex;
  align-items: center;
  justify-content: end;
}
.qrtext {
  text-align: left;
  padding-left: 10px;
}
.qrtext p {
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2.4px;
  margin: 0;
  padding-bottom: 15px;
}
.app-download {
  display: none;
  margin-bottom: 25px;
}

@media screen and (max-width: 599px) {
  .listwrapper {
    grid-template-columns: 1fr;
    margin-top: 30px !important;
  }
  .marginbgtm {
    margin-bottom: 0px;
  }
  .book-btns {
    display: grid;
  }
  .homepage + div {
    padding-bottom: 120px;
  }
  .qrcode-div img {
    display: none;
  }
  .qrtext p {
    display: none;
  }
  .qr-code-section {
    justify-content: center;
  }
  .qrtext {
    text-align: center;
    padding-left: 0;
  }
  .app-download {
    display: block;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .qr-code-section {
    justify-content: center;
  }
}

.mt15-neg {
  margin-top: -15px;
}
.mt-0 {
  margin-top: 0 !important;
}

.display-none {
  display: none;
}
.text-areadiv {
  height: 122px !important;
}
.mr-25 {
  margin-right: 25px;
}
.classes-level {
  margin: 0;
  padding: 0 35px;
  color: #737373;
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  box-sizing: border-box;
}

.display-justify {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 320px) and (max-width: 375px) {
  .classes-level {
    padding: 0 10px;
  }
  .display-justify {
    display: block;
  }
}

.infra-header {
  font-size: 46.5px;
  color: #172643;
  /* font-family: Arya; */
  margin-bottom: 20px;
  letter-spacing: 3.68px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 52px;
}

.infrasub-header {
  font-family: 'proxima-nova';
  font-size: 18px;
  font-weight: 700;
  color: #172643;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.25px;
}

.banner-btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.infraredPage {
  margin-top: -9vh;
}
.player-wrapper video {
  position: relative;
  width: 100%;
  height: 100%;

  display: inline-block;

  -webkit-mask-image: url(/images/mask-img.png);
  mask-image: url(/images/mask-img.png);

  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;

  -webkit-mask-position: center;
  mask-position: center;

  -webkit-mask-size: 100%;
  mask-size: 100%;
  margin-top: -50px;
  z-index: 7;
  position: relative;
}

/* .video {
  width: 100%;
} */
.boutique-btn {
  width: 200px !important;
}

@media (min-width: 320px) and (max-width: 599px) {
  .player-wrapper video {
    -webkit-mask-image: none;
    margin-top: 0px;
    height: 100dvh;
    width: auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .welocome-text {
    padding-bottom: 15px;
  }
}
@media (min-width: 320px) and (max-width: 599px) {
  .banner-btn {
    display: none;
  }
  .infra-header {
    font-size: 28.3px;
    line-height: 32.24px;
  }
  .infrasub-header {
    font-size: 18px;
  }
  .classes-level {
    padding: 0 20px;
  }
  .addonsdetailstext {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .addonsheadingtext {
    font-size: 16px !important;
  }
  .miles-section > button > img {
    height: 18px;
  }
  .miles-section > button {
    padding: 5px 10px;
  }
}
@media (min-width: 280px) and (max-width: 414px) {
  .mob-home-img img {
    height: 77vh;
  }
}
@media (min-width: 416px) and (max-width: 599px) {
  .mob-home-img img {
    height: 75vh;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .ipad-home-img img {
    height: 60vh;
  }
}

.video-react-controls-enabled {
  padding: 0 !important;
}

.video_holder {
  width: 320px;
  height: 96px;
  overflow: hidden;
}

.video_holder video {
  position: relative;
  top: -80px;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  z-index: 7;
}

.text-right {
  text-align: right;
}

@media screen and (max-width: 1024px) {
  .text-right {
    text-align: center;
  }

  .boutique-class {
    min-height: 560px !important;
  }
}

.GradientWrapperWhite {
  display: block;
}
.grey-openbtns {
  width: 100%;
  max-width: 440px;
  display: flex;
  background: #172643;
  border-radius: 30px;
}

.single-row {
  grid-template-columns: 1fr !important;
}
.text-offer {
  font-weight: 600;
  text-decoration: underline;
}

.selected {
  display: block !important;
  background: #ecf9ff !important;
}
.unselected {
  display: block !important;
}

.homepage {
  margin-top: 57px;
  @media screen and (max-width: 599px) {
    margin-top: 110px;
  }
  @media (min-width: 600px) and (max-width: 812px) {
    margin-top: 81px;
  }
}
.pb25 {
  margin-bottom: 25px !important;
}
@media screen and (max-width: 599px) {
  .pick-way-head {
    display: block !important;
    margin-top: -50px !important;
  }
}
